import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Divider, IconButton, TextField, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "shared/components/UIElements/Modal/Modal";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import MDButton from "shared/components/MDComponents/MDButton";
import ConfirmDeleteDialog from "shared/components/UIElements/Dialog/ConfirmDeleteDialog";
import PlaylistDescription from "./playlist-modal/PlaylistDescription";
import PlaylistOptions from "./playlist-modal/PlaylistOptions";
import PlaylistMediaList from "./playlist-modal/PlaylistMediaList";
import { updatePlaylist } from "services/PlaylistService";

const PlaylistDetailModal = ({
  open = false,
  onClose,
  venueId,
  id,
  title = "",
  description = "",
  startDate = "",
  endDate = "",
  runIndefinitely = false,
  mediaList = [],
  screenFit = "none",
  onDelete,
}) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const [editDescription, setEditDescription] = useState(description);
  const [editStartDate, setEditStartDate] = useState(startDate);
  const [editEndDate, setEditEndDate] = useState(endDate);
  const [editRunIndefinitely, setEditRunIndefinitely] =
    useState(runIndefinitely);
  const [editScreenFit, setEditScreenFit] = useState(screenFit);
  const user = useSelector((state) => state.user.user);
  const subdomain = useSelector((state) => state.subdomain.value);
  const theme = useTheme();
  const darkMode = theme.darkMode;

  const openConfirmationDialog = () => setOpenConfirm(true);
  const closeConfirmationDialog = () => setOpenConfirm(false);

  const handleDelete = () => {
    onDelete();
    closeConfirmationDialog();
  };

  const handleSave = async () => {
    const updatedPlaylist = {
      title: editTitle,
      description: editDescription,
      startDate: editStartDate ? new Date(editStartDate).toISOString() : "",
      runIndefinitely: editRunIndefinitely,
      screenFit: editScreenFit,
      mediaList: mediaList.map((item) => item._id),
      venue: venueId,
    };

    if (!editRunIndefinitely) {
      updatedPlaylist.endDate = new Date(editEndDate).toISOString();
    }

    try {
      await updatePlaylist(id, updatedPlaylist, subdomain);
      setEditing(false);
    } catch (error) {
      console.error("Error updating playlist:", error);
    }
  };

  const handleEditToggle = () => {
    if (editing) {
      handleSave();
    }
    setEditing(!editing);
  };

  const handleCancelEdit = () => {
    setEditing(false);
    setEditTitle(title);
    setEditDescription(description);
    setEditStartDate(startDate);
    setEditEndDate(endDate);
    setEditRunIndefinitely(runIndefinitely);
    setEditScreenFit(screenFit);
  };

  const truncateName = (name, maxLength = 15) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  const canDelete =
    user &&
    (user.role === "manager" ||
      user.role === "owner" ||
      user.role === "superadmin");

  return (
    <Modal show={open} onCancel={onClose}>
      <MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDTypography variant="h5">
            {editing ? (
              <TextField
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                fullWidth
                inputProps={{ maxLength: 30 }}
              />
            ) : (
              truncateName(editTitle, 50)
            )}
          </MDTypography>
          <MDBox>
            {editing ? (
              <>
                <IconButton
                  sx={{
                    color: darkMode ? "#90caf9" : "#0d47a1",
                    "&:hover": {
                      color: "#FF4081",
                    },
                  }}
                  onClick={handleSave}
                  color="primary"
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  sx={{
                    color: darkMode ? "#90caf9" : "#0d47a1",
                    "&:hover": {
                      color: "#FF4081",
                    },
                  }}
                  onClick={handleCancelEdit}
                  color="secondary"
                >
                  <CancelIcon />
                </IconButton>
              </>
            ) : (
              <IconButton
                sx={{
                  color: darkMode ? "#90caf9" : "#0d47a1",
                  "&:hover": {
                    color: "#FF4081",
                  },
                }}
                onClick={handleEditToggle}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            )}
          </MDBox>
        </MDBox>
        <Divider sx={{ my: 2 }} />

        {/* Description and Options */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <PlaylistDescription
              description={editDescription}
              editing={editing}
              onDescriptionChange={setEditDescription}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PlaylistOptions
              editing={editing}
              startDate={editStartDate}
              endDate={editEndDate}
              runIndefinitely={editRunIndefinitely}
              screenFit={editScreenFit}
              onStartDateChange={setEditStartDate}
              onEndDateChange={setEditEndDate}
              onRunIndefinitelyChange={setEditRunIndefinitely}
              onScreenFitChange={setEditScreenFit}
            />
          </Grid>
        </Grid>

        {/* Media List Section */}
        {mediaList && (
          <PlaylistMediaList
            mediaList={mediaList}
            truncateName={truncateName}
          />
        )}

        {canDelete && (
          <MDBox display="flex" justifyContent="flex-end" mt={2}>
            <MDButton
              variant="contained"
              size="small"
              color="error"
              onClick={openConfirmationDialog}
            >
              DELETE
            </MDButton>
          </MDBox>
        )}
      </MDBox>

      {/* Confirmation Dialog */}
      <ConfirmDeleteDialog
        open={openConfirm}
        onClose={closeConfirmationDialog}
        onConfirm={handleDelete}
        title="Confirm Deletion"
        description="Are you sure you want to delete this playlist? This action cannot be undone."
      />
    </Modal>
  );
};

export default PlaylistDetailModal;
