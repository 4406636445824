import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import MDBox from "shared/components/MDComponents/MDBox";
import MDButton from "shared/components/MDComponents/MDButton";
import MDTypography from "shared/components/MDComponents/MDTypography";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import { useSelector } from "react-redux";
import PlaylistInfo from "../components/new-playlist/PlaylistInfo";
import PlaylistMedia from "../components/new-playlist/PlaylistMedia";
import { createPlaylist } from "services/PlaylistService";
import Validation from "shared/components/UIElements/Validation/Validation";
import MDAlert from "shared/components/MDComponents/MDAlert";
import { fetchMediaByVenue } from "services/MediaService";
import useFetchTenant from "shared/hooks/useFetchTenant";

function NewPlaylist() {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const darkMode = theme.darkMode;
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const subdomain = useSelector((state) => state.subdomain.value);
  const { tenant } = useFetchTenant(subdomain);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const navigate = useNavigate();
  const [mediaAvailable, setMediaAvailable] = useState(true);

  const [playlistInfo, setPlaylistInfo] = useState({
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    runIndefinitely: false,
  });
  const [mediaList, setMediaList] = useState([]);
  const [showValidation, setShowValidation] = useState(false);

  const [mediaAlert, setMediaAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });
  const [limitAlert, setLimitAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const supportedVideoFormats = ["mp4", "mpeg", "mov", "mkv"];

  const isSubscriptionActive = tenant?.status === "active";
  const hasReachedContentLimit =
    tenant?.currentContentItems >= tenant?.maxContentItems;

  const showNotification = useCallback(
    (message, type, setAlertFunction, dismissable = true) => {
      setAlertFunction({ show: true, message, type, dismissable });
    },
    []
  );

  useEffect(() => {
    if (!isSubscriptionActive) {
      showNotification(
        "Your account is inactive. You can manage existing playlists, but you cannot create new ones.",
        "error",
        setLimitAlert,
        false
      );
    } else if (hasReachedContentLimit) {
      showNotification(
        "You have reached the maximum number of content items allowed. Please delete existing content or contact support to upgrade.",
        "error",
        setLimitAlert,
        false
      );
    }
  }, [isSubscriptionActive, hasReachedContentLimit, showNotification]);

  useEffect(() => {
    const fetchVenueMedia = async () => {
      try {
        const data = await fetchMediaByVenue(selectedVenue.id, subdomain);

        const videoMedia = data.media.filter((item) => {
          const extension = item.url.split(".").pop().toLowerCase();
          return supportedVideoFormats.includes(extension);
        });

        if (videoMedia.length === 0) {
          setMediaAvailable(false);
          showNotification(
            "No video media is available for this venue. Please upload video media before creating a playlist.",
            "error",
            setMediaAlert,
            false
          );
        } else {
          setMediaAlert({ show: false, message: "", type: "info" });
        }
      } catch (error) {
        showNotification(
          "There was an error fetching media for the venue.",
          "error",
          setMediaAlert
        );
        console.error("Error fetching media for venue:", error);
      }
    };

    fetchVenueMedia();
  }, [selectedVenue.id, subdomain]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setShowValidation(false);
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleCreatePlaylist = async () => {
    if (isValid()) {
      const { endDate, ...playlistForSubmission } = playlistInfo;

      const mediaIds = mediaList.map((media) => media.id);

      const playlistData = {
        playlist: {
          ...playlistForSubmission,
          venue: selectedVenue.id,
          mediaList: mediaIds,
        },
      };

      if (endDate) {
        playlistData.playlist.endDate = endDate;
      }

      try {
        await createPlaylist(playlistData, subdomain);
        navigate(`/playlists`);
      } catch (error) {
        console.log("Error submitting playlist:", error);
      }
    } else {
      setShowValidation(true);
    }
  };

  const isValid = () => {
    const { title, description, startDate, endDate, runIndefinitely } =
      playlistInfo;
    return (
      title &&
      description &&
      startDate &&
      (endDate || runIndefinitely) &&
      mediaList.length > 0
    );
  };

  const updatePlaylistInfo = useCallback((info) => {
    setPlaylistInfo(info);
  }, []);

  function getSteps() {
    return ["1. Playlist Info", "2. Media Selection"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <PlaylistInfo
            onUpdate={updatePlaylistInfo}
            playlistInfo={playlistInfo}
          />
        );
      case 1:
        return (
          <PlaylistMedia setMediaList={setMediaList} mediaList={mediaList} />
        );
      default:
        return null;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Create New Playlist
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                A Playlist is a series of videos played in order. Each video is
                played in full before moving to the next, providing seamless
                video transitions.
              </MDTypography>
            </MDBox>

            {mediaAlert.show && (
              <MDBox mb={3} sx={{ position: "relative" }}>
                <MDAlert
                  color={mediaAlert.type}
                  dismissible={mediaAlert.dismissable}
                >
                  <MDTypography variant="body2" color="white">
                    No video media is available for this venue. Please{" "}
                    <MDTypography
                      variant="body1"
                      component={Link}
                      to="/newmedia"
                      sx={{
                        color: darkMode ? "#00BFFF" : "#00BFFF",
                        textDecoration: "none",
                        "&:hover": {
                          color: darkMode ? "#ff4081" : "#344767",
                        },
                      }}
                    >
                      upload video media
                    </MDTypography>{" "}
                    before creating a playlist.
                  </MDTypography>
                </MDAlert>
              </MDBox>
            )}

            {limitAlert.show && (
              <MDBox mb={3} sx={{ position: "relative" }}>
                <MDAlert
                  color={limitAlert.type}
                  dismissible={limitAlert.dismissable}
                >
                  <MDTypography variant="body2" color="white">
                    {limitAlert.message}
                  </MDTypography>
                </MDAlert>
              </MDBox>
            )}

            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    background: `${
                      darkMode ? "#344767" : "#344767"
                    } !important`,
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                {getStepContent(activeStep)}
                {showValidation && isLastStep && (
                  <Validation
                    fields={[
                      { name: "Title", value: playlistInfo.title },
                      { name: "Description", value: playlistInfo.description },
                      { name: "Start Date", value: playlistInfo.startDate },
                      {
                        name: "End Date or Run Indefinitely",
                        value:
                          playlistInfo.endDate || playlistInfo.runIndefinitely,
                      },
                      { name: "Media", value: mediaList.length > 0 },
                    ]}
                  />
                )}
                <MDBox mt={3} display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDBox />
                  ) : (
                    <MDButton
                      variant="contained"
                      color="dark"
                      onClick={handleBack}
                    >
                      Back
                    </MDButton>
                  )}
                  <MDButton
                    variant="contained"
                    color="dark"
                    onClick={isLastStep ? handleCreatePlaylist : handleNext}
                    disabled={
                      hasReachedContentLimit ||
                      !isSubscriptionActive ||
                      !mediaAvailable
                    }
                  >
                    {isLastStep ? "Create Playlist" : "Next"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewPlaylist;
