import React from "react";
import { Card, CardContent, Grid, CardMedia } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";

const PlaylistMediaList = ({
  mediaList = [],
  truncateName = (name) => name,
}) => {
  const isVideo = (url) => {
    if (!url) return false; // Handle undefined URLs
    const videoExtensions = ["mp4", "mpeg", "mov", "mkv"];
    const extension = url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  return (
    <MDBox mt={2} borderRadius="2px">
      <Card>
        <CardContent>
          <MDTypography variant="h6" align="center">
            Media List:
          </MDTypography>
          <Grid container spacing={2}>
            {mediaList.length > 0 ? (
              mediaList.map((mediaItem, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {isVideo(mediaItem?.url) ? (
                      <CardMedia
                        component="video"
                        src={
                          mediaItem?.signedUrl ||
                          "https://placehold.co/300x150?text=No+Video"
                        }
                        controls
                        sx={{
                          width: "100%",
                          maxHeight: "150px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <CardMedia
                        component="img"
                        image={
                          mediaItem?.signedUrl ||
                          "https://placehold.co/300x150?text=No+Image"
                        }
                        alt={mediaItem?.name || "No Media"}
                        sx={{
                          width: "100%",
                          maxHeight: "150px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                    <MDTypography variant="body1" mt={1}>
                      {truncateName(mediaItem?.name || "Unnamed Media")}
                    </MDTypography>
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <CardMedia
                    component="img"
                    image="https://placehold.co/300x150?text=No+Media+Available"
                    alt="No Media"
                    sx={{
                      width: "100%",
                      maxHeight: "150px",
                      objectFit: "contain",
                    }}
                  />
                  <MDTypography
                    variant="body1"
                    align="center"
                    mt={2}
                    color="text.secondary"
                  ></MDTypography>
                </MDBox>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </MDBox>
  );
};

export default PlaylistMediaList;
