import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import MDButton from "shared/components/MDComponents/MDButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MDAlert from "shared/components/MDComponents/MDAlert";
import { performLogout } from "auth/components/logout";
import { Navigate } from "react-router-dom";

const VerifyEmail = () => {
  const { user } = useAuth0();
  const subdomain = useSelector((state) => state.subdomain.value);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  if (user?.email_verified) {
    return <Navigate to="/" replace />;
  }

  const resendVerificationEmail = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/resend-verification`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Subdomain": subdomain,
          },
          body: JSON.stringify({
            user_id: user.sub,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        setAlert({
          show: true,
          message: `Error: ${errorText}`,
          type: "error",
        });
        return;
      }

      setAlert({
        show: true,
        message:
          "Verification email resent successfully! Please check your inbox and refresh the page when you're done.",
        type: "success",
      });
    } catch (error) {
      console.error("Error resending verification email:", error);
      setAlert({
        show: true,
        message: "Failed to resend verification email. Please try again later.",
        type: "error",
      });
    }
  };

  const handleLogout = () => {
    performLogout();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Typography variant="h1">Verify Your Email</Typography>
      <Typography variant="body1" mb={3}>
        Please check your email and verify your account to continue.
      </Typography>

      {/* Alert Section */}
      {alert.show && (
        <MDAlert color={alert.type} dismissible>
          <Typography variant="body2" color="white">
            {alert.message}
          </Typography>
        </MDAlert>
      )}

      <Box display="flex" justifyContent="center" gap={2}>
        <MDButton
          variant="contained"
          color="dark"
          onClick={resendVerificationEmail}
        >
          Resend Verification Email
        </MDButton>
        <MDButton variant="contained" color="dark" onClick={handleLogout}>
          Logout
        </MDButton>
      </Box>
    </Box>
  );
};

export default VerifyEmail;
