import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Grid,
  Step,
  StepLabel,
  Stepper,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@mui/material";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import MDButton from "shared/components/MDComponents/MDButton";
import VenueInfo from "../components/new-venue/VenueInfo";
import UserAssign from "../components/new-venue/UserAssign";
import { createVenue, updateVenueBackgroundImage } from "services/VenueService";
import { fetchUsers } from "services/UserService";
import Validation from "shared/components/UIElements/Validation/Validation";
import { uploadVenueImage } from "services/MediaService";
import { useSelector } from "react-redux";
import MDAlert from "shared/components/MDComponents/MDAlert";
import useFetchTenant from "shared/hooks/useFetchTenant";

const NewVenue = () => {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const darkMode = theme.darkMode;
  const navigate = useNavigate();

  const [venueInfo, setVenueInfo] = useState({
    name: "",
    description: "",
    backgroundImage: null,
    users: [],
  });

  const [allUsers, setAllUsers] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [toggleAssignUsers, setToggleAssignUsers] = useState(false);

  const subdomain = useSelector((state) => state.subdomain.value);
  const { tenant } = useFetchTenant(subdomain);

  const [limitAlert, setLimitAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const isSubscriptionActive = tenant?.status === "active";
  const hasReachedContentLimit = tenant?.currentVenues >= tenant?.maxVenues;

  const showNotification = useCallback((message, type, setAlertFunction) => {
    setAlertFunction({ show: true, message, type });
  }, []);

  useEffect(() => {
    if (!isSubscriptionActive) {
      showNotification(
        "Your account is inactive. You can manage existing venues, but you cannot create new ones.",
        "error",
        setLimitAlert
      );
    } else if (hasReachedContentLimit) {
      showNotification(
        "You have reached the maximum number of venues allowed. Please delete existing venues or contact support to upgrade.",
        "error",
        setLimitAlert
      );
    }
  }, [isSubscriptionActive, hasReachedContentLimit, showNotification]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await fetchUsers(subdomain);
        setAllUsers(users);
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    };

    fetchData();
  }, []);

  const steps = ["1. Venue Info", "2. Assign Users"];
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setShowValidation(false);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleVenueInfoChange = (updatedInfo) => {
    setVenueInfo(updatedInfo);
  };

  const handleUserAssign = (users) => {
    setVenueInfo({ ...venueInfo, users });
  };

  const isValid = () => {
    const { name, description, backgroundImage } = venueInfo;
    return (
      name &&
      description &&
      backgroundImage &&
      (!toggleAssignUsers || venueInfo.users.length > 0)
    );
  };

  const handleCreateVenue = async () => {
    if (isValid()) {
      setLoading(true);
      try {
        const createdVenue = await createVenue(
          {
            title: venueInfo.name,
            description: venueInfo.description,
            users: venueInfo.users,
          },
          subdomain
        );
        const venueId = createdVenue._id;

        if (venueInfo.backgroundImage) {
          const mediaId = await uploadVenueImage(
            venueInfo.backgroundImage,
            venueId,
            subdomain
          );
          await updateVenueBackgroundImage(venueId, mediaId, subdomain);
        }

        navigate("/venues");
      } catch (error) {
        console.error("Error creating new venue:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setShowValidation(true);
    }
  };

  const handleToggleAssignUsers = () => {
    setToggleAssignUsers((prevAssignUsers) => {
      const newAssignUsers = !prevAssignUsers;
      if (!newAssignUsers) {
        setVenueInfo((prevVenueInfo) => ({ ...prevVenueInfo, users: [] }));
      }
      return newAssignUsers;
    });
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <VenueInfo
            onVenueInfoChange={handleVenueInfoChange}
            venueInfo={venueInfo}
            imagePreview={imagePreview}
            setImagePreview={setImagePreview}
            showNotification={(message, type) =>
              showNotification(message, type, setLimitAlert)
            }
          />
        );
      case 1:
        return (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={toggleAssignUsers}
                  onChange={handleToggleAssignUsers}
                  color="primary"
                />
              }
              label="Assign Users"
            />
            {toggleAssignUsers && (
              <UserAssign
                onUserAssign={handleUserAssign}
                users={venueInfo.users}
                allUsers={allUsers}
              />
            )}
          </>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Create New Venue
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                Configure the venue details and assign users.
              </MDTypography>
            </MDBox>

            {limitAlert.show && (
              <MDBox mb={3} sx={{ position: "relative" }}>
                <MDAlert color={limitAlert.type} dismissible>
                  <MDTypography variant="body2" color="white">
                    {limitAlert.message}
                  </MDTypography>
                </MDAlert>
              </MDBox>
            )}

            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    background: `${
                      darkMode ? "#344767" : "#344767"
                    } !important`,
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                {showValidation && isLastStep && (
                  <Validation
                    fields={[
                      { name: "Name", value: venueInfo.name },
                      { name: "Description", value: venueInfo.description },
                      {
                        name: "Users",
                        value: toggleAssignUsers
                          ? venueInfo.users.length > 0
                          : "Optional",
                      },
                      {
                        name: "Background Image",
                        value: venueInfo.backgroundImage,
                      },
                    ]}
                  />
                )}
                {getStepContent(activeStep)}
                <MDBox mt={3} display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDBox />
                  ) : (
                    <MDButton
                      variant="contained"
                      color="dark"
                      onClick={handleBack}
                    >
                      Back
                    </MDButton>
                  )}
                  <MDButton
                    variant="contained"
                    color="dark"
                    onClick={isLastStep ? handleCreateVenue : handleNext}
                    disabled={hasReachedContentLimit || !isSubscriptionActive}
                  >
                    {isLastStep ? "Create Venue" : "Next"}
                  </MDButton>
                </MDBox>
                {loading && <CircularProgress />}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default NewVenue;
