import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField as MuiTextField,
  IconButton,
} from "@mui/material";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  useNotify,
  useDataProvider,
  useRedirect,
  useCreate,
} from "react-admin";
import CustomToolbar from "../../utils/CustomToolbar";
import {
  roleChoices,
  generatePassword,
  validatePassword,
} from "../../utils/validation";
import { useAdminData } from "context/AdminContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CenteredLoading from "shared/components/UIElements/Loading/CenteredLoading";

export const UserCreate = (props) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [venues, setVenues] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  // eslint-disable-next-line
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [role, setRole] = useState("user");

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { tenants, status, error } = useAdminData();
  const redirect = useRedirect();

  useEffect(() => {
    if (selectedTenant) {
      const tenantSubdomain = selectedTenant.subdomain;
      const tenantId = selectedTenant.id;
      if (tenantId) {
        dataProvider
          .getList(
            `venue/all/${tenantId}`,
            { pagination: { page: 1, perPage: 50 } },
            {
              headers: {
                "X-Subdomain": tenantSubdomain,
              },
            }
          )
          .then(({ data }) => {
            setVenues(data);
          })
          .catch((error) => {
            notify(`Error fetching venues: ${error.message}`, {
              type: "error",
            });
          });
      }
    }
  }, [selectedTenant, dataProvider, notify]);

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setPassword(newPassword);
    notify(`Password generated: ${newPassword}`, { type: "info" });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (!validatePassword(newPassword)) {
      setPasswordError(
        "Password must be between 8-30 characters and include uppercase, lowercase, number, and special character."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleBackClick = () => {
    redirect("/admin/users");
  };

  const handleSubmit = async (data) => {
    const formData = {
      ...data,
      tenant: selectedTenant,
      password,
    };

    try {
      await dataProvider.create("users", { data: formData });
      notify("User created successfully", { type: "info" });
      redirect("/admin/users");
    } catch (error) {
      // Log the error only here
      let errorMessage = "An error occurred while creating the user.";
      if (error?.body?.error) {
        errorMessage = error.body.error;
      } else if (error.message) {
        errorMessage = error.message;
      }
      console.error("UserCreate Error:", errorMessage);
      notify(errorMessage, { type: "error" });
    }
  };

  if (status === "loading") {
    return <CenteredLoading />;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <Create {...props} resource="users">
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <h3 style={{ marginLeft: "10px" }}>Create New User</h3>
      </div>
      <SimpleForm
        toolbar={<CustomToolbar resource="users" />}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput source="name" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="email" fullWidth />
          </Grid>

          {/* Role Selection */}
          <Grid item xs={6}>
            <SelectInput
              source="role"
              choices={roleChoices}
              fullWidth
              onChange={(e) => setRole(e.target.value)}
            />
          </Grid>

          {/* Tenant Selection */}
          <Grid item xs={6}>
            <SelectInput
              label="Tenant"
              source="tenant"
              choices={tenants.map((tenant) => ({
                id: tenant.id,
                name: tenant.organizationName,
              }))}
              fullWidth
              onChange={(e) => {
                const tenant = tenants.find((t) => t.id === e.target.value);
                setSelectedTenant(tenant); // Store full tenant object
              }}
            />
          </Grid>

          {/* Venues Selection, only visible for user/manager */}
          {(role === "user" || role === "manager") && (
            <Grid item xs={12}>
              <SelectInput
                label="Venues"
                source="venues"
                choices={venues.map((venue) => ({
                  id: venue.id,
                  name: venue.title,
                }))}
                fullWidth
                multiple
                onChange={(e) => {
                  const selectedVenueIds = e.target.value;
                  setSelectedVenues(selectedVenueIds);
                }}
              />
            </Grid>
          )}

          {/* Password Field */}
          <Grid item xs={6}>
            <MuiTextField
              label="Password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              error={!!passwordError}
              helperText={passwordError}
            />
          </Grid>

          {/* Generate Password Button */}
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleGeneratePassword}>
              Generate Password
            </Button>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
