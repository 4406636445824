import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Tooltip } from "@mui/material";
import MDTypography from "shared/components/MDComponents/MDTypography";
import { useDrag } from "react-dnd";
import MediaDetailModal from "./MediaDetailModal";
import { deleteMedia } from "services/MediaService";
import { fetchFolderById } from "services/FolderService";
import { useSelector } from "react-redux";

const MediaItem = ({
  id,
  name,
  url,
  signedImageUrl,
  folder,
  venueId,
  spacing,
  selectedFolder,
  isSelected,
  folders,
  onMediaClick,
}) => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(selectedFolder || null);
  const [errorMessage, setErrorMessage] = useState("");
  const itemSize = spacing * 5; // Example conversion, adjust as needed.
  const fontSize = Math.max(12, spacing / 2); // Dynamically adjust font size
  const subdomain = useSelector((state) => state.subdomain.value);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "media",
    item: { id, name, url, signedImageUrl, folder, venueId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    if (isSelected) {
      setShowDetailModal(true);
    }
  }, [isSelected]);

  useEffect(() => {
    if (showDetailModal && !currentFolder) {
      const loadFolderDetails = async () => {
        if (typeof folder === "string") {
          try {
            const folderDetails = await fetchFolderById(folder, subdomain);
            setCurrentFolder(folderDetails);
          } catch (error) {
            console.error("Error fetching folder details:", error);
          }
        } else {
          setCurrentFolder(folder);
        }
      };

      loadFolderDetails();
    }
  }, [showDetailModal, folder, currentFolder]);

  const toggleDetailModal = () => setShowDetailModal(!showDetailModal);

  const handleModalClose = () => {
    setShowDetailModal(false);
    setErrorMessage("");
  };

  const confirmDeleteHandler = async () => {
    try {
      await deleteMedia(id, subdomain);
      setIsDeleted(true);
    } catch (error) {
      console.error("Error deleting media:", error);
      const errorMessage =
        error.message || "Failed to delete media due to an unknown error.";
      setErrorMessage(errorMessage); // Pass the error to the parent
    }
  };

  const handleUpdateMedia = (updatedMedia) => {
    const updatedFolder = folders.find(
      (folder) => folder.id === updatedMedia.folder
    );
    setCurrentFolder(updatedFolder);
  };

  if (isDeleted) {
    return null;
  }

  const handleClick = () => {
    if (onMediaClick) {
      onMediaClick({
        id,
        name,
        url,
        signedImageUrl,
      });
    } else {
      toggleDetailModal();
    }
  };

  const truncateName = (name, maxLength = 15) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  const isVideo = (url) => {
    const videoExtensions = ["mp4", "mpeg", "mov", "mkv"];
    const extension = url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  return (
    <React.Fragment>
      <MediaDetailModal
        open={showDetailModal}
        onClose={handleModalClose}
        clearError={() => setErrorMessage("")}
        title={name}
        folder={currentFolder}
        onDelete={confirmDeleteHandler}
        mediaUrl={signedImageUrl}
        originalUrl={url}
        venueId={venueId}
        id={id}
        folders={folders}
        onUpdate={handleUpdateMedia}
        errorMessage={errorMessage}
      />

      <Box
        ref={drag}
        sx={{
          width: `${itemSize}px`,
          m: 0,
          textAlign: "center",
          opacity: isDragging ? 0.5 : 1,
        }}
        onClick={handleClick}
      >
        <Tooltip>
          {isVideo(url) ? (
            <Box
              sx={{
                width: "100%",
                height: `${itemSize}px`,
                borderRadius: "10px",
                overflow: "hidden",
                transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
                },
              }}
            >
              <video
                src={signedImageUrl}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                opacity: isDragging ? 0.5 : 1,
                cursor: "pointer",
                width: "100%",
                height: `${itemSize}px`,
                borderRadius: "10px",
                overflow: "hidden",
                backgroundImage: `url(${signedImageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
                },
              }}
            />
          )}
        </Tooltip>
        <MDTypography variant="h6" sx={{ fontSize: `${fontSize}px`, mt: 1 }}>
          {truncateName(name)}
        </MDTypography>
      </Box>
    </React.Fragment>
  );
};

MediaItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  signedImageUrl: PropTypes.string.isRequired,
  venueId: PropTypes.string,
  spacing: PropTypes.number.isRequired,
  selectedFolder: PropTypes.object,
  isSelected: PropTypes.bool.isRequired,
  folders: PropTypes.array.isRequired,
  onMediaClick: PropTypes.func,
};

export default MediaItem;
