import { useEffect, useState } from "react";

import "./styles/sidenav.css";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "../../MDComponents/MDBox";
import MDTypography from "../../MDComponents/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setDarkMode,
} from "context/ComponentContext";
import { Switch } from "@mui/material";

import routes from "routes";
import { useSelector } from "react-redux";

function Sidenav({ color = "info", brand = "", brandName, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);
  const { user } = useSelector((state) => state.user);
  const { selectedVenue } = useSelector((state) => state.venue);

  // In Sidenav component

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, [collapseName, itemParentName]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, transparentSidenav, whiteSidenav]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const MDComponents = collapse
      .filter(
        ({ requiredRoles }) =>
          !requiredRoles || (user && requiredRoles.includes(user.role))
      )
      .map(({ name, route, key, href }) =>
        href ? (
          <Link
            key={key}
            href={href}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} nested />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={route === pathname} nested />
          </NavLink>
        )
      );

    return MDComponents.length > 0 ? MDComponents : null;
  };

  // Render the all the collapses from the routes.js
  const renderCollapse = (collapses) =>
    collapses
      .filter(({ collapse, requiredRoles }) => {
        const hasAccessibleItems =
          collapse &&
          collapse.some(
            (item) =>
              !item.requiredRoles ||
              (user && item.requiredRoles.includes(user.role))
          );

        return (
          (!requiredRoles || (user && requiredRoles.includes(user.role))) &&
          (hasAccessibleItems || !collapse)
        );
      })
      .map(({ name, collapse, route, href, key }) => {
        let returnValue;

        if (collapse) {
          const nestedItems = renderNestedCollapse(collapse);
          if (!nestedItems) return null; // Don't render if there are no nested items

          returnValue = (
            <SidenavItem
              key={key}
              color={color}
              name={name}
              active={key === itemParentName ? "isParent" : false}
              open={openNestedCollapse === key}
              onClick={({ currentTarget }) =>
                openNestedCollapse === key &&
                currentTarget.classList.contains("MuiListItem-root")
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key)
              }
            >
              {nestedItems}
            </SidenavItem>
          );
        } else {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavItem
                color={color}
                name={name}
                active={key === itemName}
              />
            </Link>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem
                color={color}
                name={name}
                active={key === itemName}
              />
            </NavLink>
          );
        }
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes
    .filter(({ type, key, collapse, requiredRoles }) => {
      if (!selectedVenue && type === "collapse") {
        return [
          "venues",
          "users",
          "help",
          "loggedin-user",
          "admin-panel",
        ].includes(key);
      }

      const hasAccessibleItems =
        collapse &&
        collapse.some(
          (item) =>
            !item.requiredRoles ||
            (user && item.requiredRoles.includes(user.role))
        );

      return (
        (!requiredRoles || (user && requiredRoles.includes(user.role))) &&
        (hasAccessibleItems || !collapse)
      );
    })
    .map(
      ({
        type,
        name,
        icon,
        title,
        collapse,
        noCollapse,
        key,
        href,
        route,
        requiredRoles,
      }) => {
        if (
          !requiredRoles ||
          (user && requiredRoles.includes(user.role)) ||
          (collapse &&
            collapse.some(
              (item) =>
                !item.requiredRoles ||
                (user && item.requiredRoles.includes(user.role))
            ))
        ) {
          let returnValue;

          if (type === "collapse") {
            if (href) {
              returnValue = (
                <Link
                  href={href}
                  key={key}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ textDecoration: "none" }}
                >
                  <SidenavCollapse
                    name={name}
                    icon={icon}
                    active={key === collapseName}
                    noCollapse={noCollapse}
                  />
                </Link>
              );
            } else if (noCollapse && route) {
              returnValue = (
                <NavLink to={route} key={key}>
                  <SidenavCollapse
                    name={name}
                    icon={icon}
                    noCollapse={noCollapse}
                    active={key === collapseName}
                  >
                    {collapse ? renderCollapse(collapse) : null}
                  </SidenavCollapse>
                </NavLink>
              );
            } else {
              returnValue = (
                <SidenavCollapse
                  key={key}
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  open={openCollapse === key}
                  onClick={() =>
                    openCollapse === key
                      ? setOpenCollapse(false)
                      : setOpenCollapse(key)
                  }
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              );
            }
          } else if (type === "title") {
            returnValue = (
              <MDTypography
                key={key}
                color={textColor}
                display="block"
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                {title}
              </MDTypography>
            );
          } else if (type === "divider") {
            returnValue = (
              <Divider
                key={key}
                light={
                  (!darkMode && !whiteSidenav && !transparentSidenav) ||
                  (darkMode && !transparentSidenav && whiteSidenav)
                }
              />
            );
          }

          return returnValue;
        }
        return null;
      }
    );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && (
            <MDBox component="img" src={brand} alt="Brand" width="2rem" /> //This is the brand logo
          )}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>

      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        lineHeight={1}
        p={4} // Add padding for aesthetics
      >
        <MDTypography
          component="h6"
          variant="button"
          fontWeight="medium"
          color={textColor}
        >
          Light / Dark
        </MDTypography>
        <Switch checked={darkMode} onChange={handleDarkMode} />
      </MDBox>
    </SidenavRoot>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
};

export default Sidenav;
