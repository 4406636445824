import React, { useState } from "react";
import {
  Grid,
  CardMedia,
  CardContent,
  Card,
  TextField,
  IconButton,
  Box,
  Alert,
  useTheme,
} from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const SlideshowMediaList = ({
  mediaList,
  truncateName,
  onUpdateMedia,
  onSave,
}) => {
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editTitle, setEditTitle] = useState("");
  const [editDuration, setEditDuration] = useState("5");
  const [validationMessage, setValidationMessage] = useState("");
  const theme = useTheme();
  const darkMode = theme.darkMode;

  const handleEdit = (index) => {
    if (editingIndex === index) {
      setEditingIndex(-1);
      setEditTitle("");
      setEditDuration("5");
      setValidationMessage("");
    } else {
      setEditingIndex(index);
      setEditTitle(mediaList[index].title);
      setEditDuration(mediaList[index].duration.toString());
      setValidationMessage("");
    }
  };

  const handleCancel = () => {
    setEditingIndex(-1);
    setEditTitle("");
    setEditDuration("5");
    setValidationMessage("");
  };

  const handleSaveItem = (index) => {
    const durationValue = parseInt(editDuration, 10);

    if (durationValue >= 5 && durationValue <= 60 && durationValue % 5 === 0) {
      const updatedMediaList = mediaList.map((item, idx) =>
        idx === index
          ? {
              ...item,
              title: editTitle,
              duration: durationValue,
            }
          : item
      );
      onUpdateMedia(updatedMediaList);
      onSave(updatedMediaList);
      setEditingIndex(-1);
      setEditTitle("");
      setEditDuration("5");
      setValidationMessage("");
    } else {
      setValidationMessage(
        "Duration must be between 5 and 60 seconds and in multiples of 5."
      );
    }
  };

  const handleDurationInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setEditDuration(value);

      if (
        value === "" ||
        (parseInt(value, 10) >= 5 && parseInt(value, 10) <= 60)
      ) {
        setValidationMessage("");
      } else {
        setValidationMessage("Duration must be between 5 and 60 seconds.");
      }
    } else {
      setValidationMessage("Only numerical values are allowed.");
    }
  };

  const handleDurationInputBlur = () => {
    const value = parseInt(editDuration, 10);
    if (value >= 5 && value <= 60 && value % 5 === 0) {
      setValidationMessage("");
    } else {
      setEditDuration(mediaList[editingIndex].duration.toString());
      setValidationMessage("Duration must be in multiples of 5 seconds.");
    }
  };

  const incrementDuration = () => {
    const newDuration =
      parseInt(editDuration, 10) + 5 > 60 ? 5 : parseInt(editDuration, 10) + 5;
    setEditDuration(newDuration.toString());
    setValidationMessage("");
  };

  const decrementDuration = () => {
    const newDuration =
      parseInt(editDuration, 10) - 5 < 5 ? 60 : parseInt(editDuration, 10) - 5;
    setEditDuration(newDuration.toString());
    setValidationMessage("");
  };

  return (
    <MDBox mt={2} borderRadius="2px">
      <Card>
        <CardContent>
          <MDTypography variant="h6" align="center">
            Media List:
          </MDTypography>
          <Grid container spacing={2}>
            {mediaList.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  position="relative"
                >
                  <CardMedia
                    component="img"
                    image={
                      item.media?.signedUrl ||
                      "https://placehold.co/300x150?text=No+Image"
                    }
                    alt={item.title}
                    sx={{
                      width: "100%",
                      maxHeight: "150px",
                      objectFit: "cover",
                      position: "relative",
                    }}
                  />
                  <IconButton
                    onClick={() => handleEdit(index)}
                    color="primary"
                    sx={{
                      position: "absolute",
                      top: 16,
                      right: 0,
                      color: darkMode ? "#90caf9" : "#0d47a1",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      borderTopRightRadius: "10px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      borderTopLeftRadius: "0px",
                      padding: "4px",
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.7)",
                        color: "#FF4081",
                      },
                    }}
                  >
                    {editingIndex === index ? <CancelIcon /> : <EditIcon />}
                  </IconButton>
                  {editingIndex === index ? (
                    <>
                      <TextField
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                        fullWidth
                        label="Title"
                        sx={{ mt: 1 }}
                        inputProps={{ maxLength: 30 }}
                      />
                      <Box display="flex" alignItems="center">
                        <Box display="flex" flexDirection="column">
                          <IconButton
                            sx={{
                              color: darkMode ? "#90caf9" : "#0d47a1",
                              "&:hover": {
                                color: "#FF4081",
                              },
                            }}
                            onClick={incrementDuration}
                            size="small"
                          >
                            <ArrowDropUpIcon />
                          </IconButton>
                          <IconButton
                            sx={{
                              color: darkMode ? "#90caf9" : "#0d47a1",
                              "&:hover": {
                                color: "#FF4081",
                              },
                            }}
                            onClick={decrementDuration}
                            size="small"
                          >
                            <ArrowDropDownIcon />
                          </IconButton>
                        </Box>
                        <TextField
                          value={editDuration}
                          onChange={handleDurationInputChange}
                          onBlur={handleDurationInputBlur}
                          inputProps={{
                            style: { textAlign: "center" },
                            maxLength: 2,
                            inputMode: "numeric",
                          }}
                          sx={{ width: 50, textAlign: "center", mx: 1 }}
                        />
                        <MDTypography variant="body2" sx={{ marginRight: 1 }}>
                          seconds
                        </MDTypography>
                      </Box>
                      {validationMessage && (
                        <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
                          {validationMessage}
                        </Alert>
                      )}
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <IconButton
                          sx={{
                            color: darkMode ? "#90caf9" : "#0d47a1",
                            "&:hover": {
                              color: "#FF4081",
                            },
                          }}
                          onClick={() => handleSaveItem(index)}
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            color: darkMode ? "#90caf9" : "#0d47a1",
                            "&:hover": {
                              color: "#FF4081",
                            },
                          }}
                          onClick={handleCancel}
                        >
                          <CancelIcon />
                        </IconButton>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <MDTypography variant="body1" mt={1}>
                        {truncateName(item.title)}
                      </MDTypography>
                      <MDTypography variant="body2">
                        Duration: {item.duration} seconds
                      </MDTypography>
                    </>
                  )}
                </MDBox>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </MDBox>
  );
};

export default SlideshowMediaList;
