import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MDBox from "shared/components/MDComponents/MDBox";
import MDButton from "shared/components/MDComponents/MDButton";
import ProfileInfoCard from "shared/components/UIElements/InfoCards/ProfileInfoCard";
import { useDispatch } from "react-redux";
import { setSelectedVenue } from "features/venue/store/VenueSlice";
import { updateVenue } from "services/VenueService";
import { useSelector } from "react-redux";

const VenueInfo = ({ venue, onUpdate, canEdit }) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(venue?.title || "");
  const [description, setDescription] = useState(venue?.description || "");
  const subdomain = useSelector((state) => state.subdomain.value);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
    setTitle(venue?.title || "");
    setDescription(venue?.description || "");
  };

  const handleSave = async () => {
    const updatedVenueData = {
      title,
      description,
    };

    try {
      await updateVenue(venue._id, updatedVenueData, subdomain);
      onUpdate();
      dispatch(setSelectedVenue({ ...venue, title, description }));
      setEditing(false);
    } catch (error) {
      console.error("Error updating venue:", error);
    }
  };

  return (
    <ProfileInfoCard
      title="Venue Description"
      description={editing ? "" : venue?.description || ""}
      info={
        {
          // Name: editing ? "" : venue?.title || "",
          // Location: venue?.location || "Not provided",
        }
      }
      action={
        canEdit
          ? {
              onClick: handleEdit,
              tooltip: "Edit Venue",
            }
          : null
      }
      shadow={false}
    >
      {editing && (
        <>
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ mb: 2 }}
            inputProps={{ maxLength: 30 }}
          />
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            inputProps={{ maxLength: 200 }}
          />
          <MDBox mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              onClick={handleSave}
              variant="contained"
              color="dark"
              size="small"
            >
              Save
            </MDButton>
            <MDButton
              onClick={handleCancel}
              variant="contained"
              color="dark"
              size="small"
              sx={{ ml: 1 }}
            >
              Cancel
            </MDButton>
          </MDBox>
        </>
      )}
    </ProfileInfoCard>
  );
};

export default VenueInfo;
