import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ScheduleList from "../components/ScheduleList";

import "./Schedules.css";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import MDBox from "shared/components/MDComponents/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@mui/material";
import MDTypography from "shared/components/MDComponents/MDTypography";
import MDButton from "shared/components/MDComponents/MDButton";
import { useSelector } from "react-redux";
import { fetchSchedulesByVenue } from "services/ScheduleService";

const Schedules = () => {
  const [schedulesData, setSchedulesData] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [spacing, setSpacing] = useState(30);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("title");
  const [hasSchedulesInitially, setHasSchedulesInitially] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const subdomain = useSelector((state) => state.subdomain.value);
  const venueId = selectedVenue ? selectedVenue.id : null;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchSchedules = async () => {
      setIsLoading(true);
      try {
        const data = await fetchSchedulesByVenue(venueId, subdomain);
        if (data.schedules.length === 0) {
          setErrorMessage(data.message);
          setHasSchedulesInitially(false);
        } else {
          setSchedulesData(data.schedules);
          setHasSchedulesInitially(true);
        }
      } catch (error) {
        console.log("Error fetching schedules:", error);
        setErrorMessage("Error occurred while fetching schedules");
        setSchedulesData([]);
        setHasSchedulesInitially(false);
      }
      setIsLoading(false);
    };

    fetchSchedules();
  }, [venueId, selectedVenue]);

  const handleSpacingChange = (event, newValue) => {
    setSpacing(newValue);
  };
  const adjustedSpacing = Math.max(1, Math.floor(spacing / 10));

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  // Sorting functionality
  const filteredAndSortedSchedules = schedulesData
    .filter((schedule) =>
      schedule.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOption === "type") {
        const typeA = a.type || "";
        const typeB = b.type || "";
        return typeA.localeCompare(typeB);
      } else {
        return a.title.localeCompare(b.title);
      }
    });

  const schedulesWithDefaultImage = filteredAndSortedSchedules.map(
    (schedule) => ({
      ...schedule,
      image:
        schedule.showings &&
        schedule.showings.length > 0 &&
        schedule.showings[0]?.signedImageUrl
          ? schedule.showings[0].signedImageUrl
          : "https://static6.businessinsider.com/image/5c9911b07563b04a4b7c30bc-1487/screen-shot-2019-03-25-at-122108-pm.png", // Default image URL
    })
  );
  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(80vh - 64px)",
          }}
        >
          <CircularProgress />
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox py={3}>
        <Card
          sx={{
            padding: 2,
            maxWidth: "800px", // Set your desired maximum width
          }}
        >
          <Grid container alignItems="center" spacing={2}>
            {/* Search Bar */}
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Search Schedules"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>

            {/* Sort By Dropdown */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Sort By</InputLabel>
                <Select
                  value={sortOption}
                  label="Sort By"
                  onChange={handleSortChange}
                  sx={{
                    height: "40px", // Ensure Select fills the FormControl
                  }}
                >
                  <MenuItem value="title">Title</MenuItem>
                  {/* Add other sort options here */}
                </Select>
              </FormControl>
            </Grid>

            {/* Slider */}
            <Grid item xs={12} sm={4}>
              <Slider
                value={spacing}
                onChange={handleSpacingChange}
                aria-labelledby="spacing-slider"
                min={30}
                max={50}
                sx={{
                  "& .MuiSlider-rail": {
                    opacity: 1,
                    bgcolor: "#c0c0c0", // Example: a light gray color
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>

        <MDBox p={2}>
          {schedulesWithDefaultImage.length > 0 ? (
            <Grid container spacing={adjustedSpacing}>
              {schedulesWithDefaultImage.map((schedule) => (
                <Grid item key={schedule.id}>
                  <ScheduleList
                    item={schedule}
                    spacing={spacing}
                    selectedItemId={location.state?.selectedItemId}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            hasSchedulesInitially === false && (
              <MDBox
                mt={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "800px",
                }}
              >
                <MDTypography variant="h5" color="text">
                  {errorMessage}
                </MDTypography>
                <MDBox mt={2}>
                  <MDButton
                    variant="contained"
                    color="dark"
                    onClick={() => navigate(`/newschedule`)}
                  >
                    Create Schedule
                  </MDButton>
                </MDBox>
              </MDBox>
            )
          )}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default Schedules;
