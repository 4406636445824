import React from "react";
import { AppBar } from "react-admin";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CustomAppBar = (props) => {
  const navigate = useNavigate();

  const handleBackToApp = () => {
    navigate("/"); // Adjust the path to match your app's route
  };

  return (
    <AppBar {...props}>
      <Typography variant="h6" color="inherit" id="react-admin-title" />

      <Box sx={{ display: "flex", alignItems: "center", ml: "auto", gap: 4 }}>
        <Button variant="contained" color="primary" onClick={handleBackToApp}>
          Back to App
        </Button>
      </Box>
    </AppBar>
  );
};

export default CustomAppBar;
