import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import PlaylistDetailModal from "./PlaylistDetailModal";
import { deletePlaylist } from "services/PlaylistService";
import { useSelector } from "react-redux";

const PlaylistItem = ({
  id,
  image,
  title,
  description,
  mediaList,
  startDate,
  endDate,
  screenFit,
  runIndefinitely,
  spacing,
  venueId,
  isSelected,
}) => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const itemSize = spacing * 5;
  const fontSize = Math.max(12, spacing / 2);
  const subdomain = useSelector((state) => state.subdomain.value);

  const toggleDetailModal = () => setShowDetailModal(!showDetailModal);

  const confirmDeleteHandler = async () => {
    try {
      await deletePlaylist(id, subdomain);
      setIsDeleted(true);
    } catch (error) {
      console.error("Error deleting playlist:", error);
    }
  };

  const truncateTitle = (text, maxLength = 20) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (isSelected) {
      setShowDetailModal(true);
    }
  }, [isSelected]);

  if (isDeleted) {
    return null;
  }

  // Determine whether to show a video or fallback to an image
  const videoUrl = mediaList[0]?.signedUrl;
  const fallbackImage = image || "https://placehold.co/300x150?text=No+Media";

  return (
    <React.Fragment>
      <PlaylistDetailModal
        open={showDetailModal}
        onClose={toggleDetailModal}
        title={title}
        description={description}
        screenFit={screenFit}
        startDate={startDate}
        endDate={endDate}
        runIndefinitely={runIndefinitely}
        mediaList={mediaList}
        onDelete={confirmDeleteHandler}
        id={id}
        venueId={venueId}
      />

      <Box sx={{ width: `${itemSize}px`, m: 0, textAlign: "center" }}>
        <Box
          sx={{
            cursor: "pointer",
            width: "100%",
            height: `${itemSize}px`,
            borderRadius: "10px",
            overflow: "hidden",
            backgroundImage: `url(${fallbackImage})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
            },
            position: "relative", // For proper video positioning
          }}
          onClick={toggleDetailModal}
        >
          {videoUrl && (
            <video
              src={videoUrl}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              muted
              loop
              playsInline
              controls={false}
            />
          )}
        </Box>

        <MDTypography variant="h6" sx={{ fontSize: `${fontSize}px`, mt: 1 }}>
          {truncateTitle(title)}
        </MDTypography>
      </Box>

      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        lineHeight={1}
      ></MDBox>
    </React.Fragment>
  );
};

PlaylistItem.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  runIndefinitely: PropTypes.bool.isRequired,
  mediaList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      folder: PropTypes.string.isRequired,
      signedUrl: PropTypes.string,
    })
  ).isRequired,
  spacing: PropTypes.number.isRequired,
};

export default PlaylistItem;
