import React from "react";
import { Grid } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import CustomAvatar from "shared/components/UIElements/Avatar/CustomAvatar";

const MiniScheduleDetailModal = ({ schedule }) => {
  return (
    <MDBox p={1}>
      <MDTypography variant="subtitle1" align="center">
        {schedule?.title}
      </MDTypography>
      <MDTypography variant="body2" align="center" sx={{ mb: 2 }}>
        {schedule?.description}
      </MDTypography>

      {/* Miniature Showings as Avatars */}
      {schedule?.showings && (
        <Grid container spacing={1} justifyContent="center">
          {schedule.showings.map((showing, index) => (
            <Grid item key={index}>
              <CustomAvatar
                src={
                  showing?.signedImageUrl ||
                  "https://placehold.co/300x150?text=No+Image"
                }
                alt={showing.name}
                size={80}
                title={`${showing.name} (${showing.startTime} - ${showing.endTime})`}
                url={showing.url}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </MDBox>
  );
};

export default MiniScheduleDetailModal;
