import React from "react";
import { Grid } from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import CustomAvatar from "shared/components/UIElements/Avatar/CustomAvatar";

const MiniSlideshowDetailModal = ({ slideshow }) => {
  return (
    <MDBox p={1}>
      <MDTypography variant="subtitle1" align="center">
        {slideshow?.title}
      </MDTypography>
      <MDTypography variant="body2" align="center" sx={{ mb: 2 }}>
        {slideshow?.description}
      </MDTypography>

      {/* Miniature Media Items as Avatars */}
      {slideshow?.mediaList && (
        <Grid container spacing={1} justifyContent="center">
          {slideshow.mediaList.map((mediaItem, index) => (
            <Grid item key={index}>
              <CustomAvatar
                src={
                  mediaItem.media?.signedUrl ||
                  "https://placehold.co/300x150?text=No+Image"
                }
                alt={mediaItem.media.name}
                size={80}
                title={`${mediaItem.media.name}`}
                url={mediaItem.media.url}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </MDBox>
  );
};

export default MiniSlideshowDetailModal;
