// src/api/MediaService.js
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchMedia = async (venueId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/media/venue/${venueId}`, {
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch media.");
    }
    const data = await response.json();
    return data.media;
  } catch (error) {
    console.error("Error fetching media:", error);
    throw error;
  }
};

export const fetchMediaByVenue = async (venueId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/media/venue/${venueId}`, {
      method: "GET",
      headers: {
        "X-Subdomain": subdomain,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch media.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching media:", error);
    throw error;
  }
};

export const updateMedia = async (mediaId, updatedMedia, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/media/${mediaId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Subdomain": subdomain,
      },
      body: JSON.stringify(updatedMedia),
    });

    if (!response.ok) {
      throw new Error("Failed to update media");
    }

    const data = await response.json();
    return data.updatedMedia;
  } catch (error) {
    console.error("Error updating media:", error);
    throw error;
  }
};

export const uploadMedia = async (formData, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/media`, {
      method: "POST",
      headers: {
        "X-Subdomain": subdomain,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to upload media");
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error("Error sending media to server:", error);
    throw error;
  }
};

export const uploadVenueImage = async (image, venueId, subdomain) => {
  try {
    const formData = new FormData();
    formData.append("media", image);
    formData.append("venue", venueId);

    const response = await fetch(`${API_BASE_URL}/api/media/venue-image`, {
      method: "POST",
      headers: {
        "X-Subdomain": subdomain,
      },
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "Failed to upload background image."
      );
    }

    const mediaData = await response.json();
    return mediaData.media[0]._id;
  } catch (error) {
    console.error("Error uploading venue image:", error);
    throw error;
  }
};

export const deleteMedia = async (mediaId, subdomain) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/media/${mediaId}`, {
      method: "DELETE",
      headers: {
        "X-Subdomain": subdomain,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to delete media");
    }
    return true;
  } catch (error) {
    throw error;
  }
};
