import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ScreenList from "../components/ScreenList";
import "./Screens.css";
import DashboardLayout from "shared/components/UIElements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/components/Navigation/DashboardNavbar";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import MDButton from "shared/components/MDComponents/MDButton";
import { Card, CircularProgress, Grid } from "@mui/material";
import ScreenFilterControls from "../components/ScreenFilterControls";
import { fetchScreensByVenue } from "services/ScreenService";
import monitor from "assets/images/monitor2.png";

const Screens = () => {
  const [screensData, setScreensData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [spacing, setSpacing] = useState(30);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("title");
  const [hasScreensInitially, setHasScreensInitially] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const navigate = useNavigate();
  const venueId = selectedVenue ? selectedVenue.id : null;
  const location = useLocation();
  const subdomain = useSelector((state) => state.subdomain.value);

  useEffect(() => {
    const loadScreens = async () => {
      setIsLoading(true);
      try {
        const data = await fetchScreensByVenue(venueId, subdomain);
        if (data.screens.length === 0) {
          setErrorMessage(data.message);
        } else {
          setScreensData(data.screens);
        }
      } catch (error) {
        console.log("Error fetching screens:", error);
        setErrorMessage("Error occurred while fetching screens");
        setScreensData([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (venueId) {
      loadScreens();
    }
  }, [venueId]);

  const handleSpacingChange = (event, newValue) => {
    setSpacing(newValue);
  };
  const adjustedSpacing = Math.max(1, Math.floor(spacing / 10));

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const filteredScreens = screensData
    .filter(
      (screen) =>
        screen.venue === venueId &&
        screen.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOption === "title") {
        return a.title.localeCompare(b.title);
      }
      return 0; // Add other sort options here
    });
  // Set default image URL for each item
  const screensWithDefaultImage = filteredScreens.map((screen) => ({
    ...screen,
    image: screen.image || monitor,
  }));

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(80vh - 64px)",
          }}
        >
          <CircularProgress />
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox py={3}>
        <Card
          sx={{
            padding: 2,
            maxWidth: "800px", // Set your desired maximum width
          }}
        >
          <ScreenFilterControls
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
            sortOption={sortOption}
            onSortChange={handleSortChange}
            spacing={spacing}
            onSpacingChange={handleSpacingChange}
          />
        </Card>

        <MDBox p={2}>
          {screensWithDefaultImage.length > 0 ? (
            <Grid container spacing={adjustedSpacing}>
              {screensWithDefaultImage.map((screen) => (
                <Grid item key={screen.id}>
                  <ScreenList
                    item={screen}
                    spacing={spacing}
                    selectedItemId={location.state?.selectedItemId}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <MDBox
              mt={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "800px",
              }}
            >
              <MDTypography variant="h5" color="text">
                {errorMessage || "No screens found."}
              </MDTypography>
              <MDBox mt={2}>
                <MDButton
                  variant="contained"
                  color="dark"
                  onClick={() => navigate(`/newscreen`)}
                >
                  Create Screen
                </MDButton>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default Screens;
