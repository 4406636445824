import React, { useState } from "react";
import {
  Grid,
  CardMedia,
  CardContent,
  Card,
  TextField,
  IconButton,
  useTheme,
} from "@mui/material";
import MDBox from "shared/components/MDComponents/MDBox";
import MDTypography from "shared/components/MDComponents/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import TimeModal from "shared/components/UIElements/Modal/TimeModal";

const ScheduleShowings = ({ showings, truncateName, onUpdate, onSave }) => {
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editName, setEditName] = useState("");
  const [editStartTime, setEditStartTime] = useState("");
  const [editEndTime, setEditEndTime] = useState("");
  const [isTimeModalOpen, setTimeModalOpen] = useState(false);
  const [overlapMessage, setOverlapMessage] = useState("");
  const theme = useTheme();
  const darkMode = theme.darkMode;

  const handleEdit = (index) => {
    if (editingIndex === index) {
      setEditingIndex(-1);
      setEditName("");
      setEditStartTime("");
      setEditEndTime("");
    } else {
      setEditingIndex(index);
      setEditName(showings[index].name);
      setEditStartTime(showings[index].startTime);
      setEditEndTime(showings[index].endTime);
    }
  };

  const handleCancel = () => {
    setEditingIndex(-1);
    setEditName("");
    setEditStartTime("");
    setEditEndTime("");
  };

  const handleSaveItem = (index) => {
    const updatedShowings = showings.map((showing, idx) =>
      idx === index
        ? {
            ...showing,
            name: editName,
            startTime: editStartTime,
            endTime: editEndTime,
          }
        : showing
    );
    onUpdate(updatedShowings);
    onSave(updatedShowings[index]);
    setEditingIndex(-1);
    setEditName("");
    setEditStartTime("");
    setEditEndTime("");
  };

  const handleTimeSubmit = (startTime, endTime) => {
    const overlaps = isTimeOverlap(startTime, endTime, editingIndex);

    if (overlaps.length > 0) {
      const overlapMessages = overlaps
        .map(
          (overlap) =>
            `'${overlap.name}' at ${overlap.startTime} - ${overlap.endTime}`
        )
        .join(" and ");
      setOverlapMessage(
        `The time overlaps with ${overlapMessages}. Please choose another time.`
      );
    } else {
      setEditStartTime(startTime);
      setEditEndTime(endTime);
      setOverlapMessage("");
      setTimeModalOpen(false);
    }
  };

  const handleTimeModalOpen = () => {
    setTimeModalOpen(true);
  };

  const isTimeOverlap = (startTime, endTime, currentIndex) => {
    const start = new Date(`01/01/2020 ${startTime}`).getTime();
    const end = new Date(`01/01/2020 ${endTime}`).getTime();

    return showings.filter((showing, index) => {
      // Exclude the current showing being edited
      if (index === currentIndex) {
        return false;
      }

      const existingStart = new Date(
        `01/01/2020 ${showing.startTime}`
      ).getTime();
      const existingEnd = new Date(`01/01/2020 ${showing.endTime}`).getTime();

      // Check if the new time overlaps with any existing showings
      return start < existingEnd && end > existingStart;
    });
  };

  return (
    <MDBox mt={2} borderRadius="2px">
      <Card>
        <CardContent>
          <MDTypography variant="h6" align="center">
            Showings:
          </MDTypography>
          <Grid container spacing={2}>
            {showings.map((showing, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  position="relative"
                >
                  <CardMedia
                    component="img"
                    image={
                      showing?.signedImageUrl ||
                      "https://placehold.co/300x150?text=No+Image"
                    }
                    alt={showing.name}
                    sx={{
                      width: "100%",
                      maxHeight: "150px",
                      objectFit: "cover",
                      position: "relative",
                    }}
                  />
                  <IconButton
                    onClick={() => handleEdit(index)}
                    color="primary"
                    sx={{
                      position: "absolute",
                      top: 16,
                      right: 0,
                      backgroundColor: "rgba(0,0,0,0.5)",
                      borderTopRightRadius: "10px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      borderTopLeftRadius: "0px",
                      padding: "4px",
                      color: darkMode ? "#90caf9" : "#0d47a1",
                      "&:hover": {
                        color: "#FF4081",
                        backgroundColor: "rgba(0,0,0,0.7)",
                      },
                    }}
                  >
                    {editingIndex === index ? <CancelIcon /> : <EditIcon />}
                  </IconButton>
                  {editingIndex === index ? (
                    <>
                      <TextField
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                        fullWidth
                        label="Name"
                        sx={{ mt: 1 }}
                        inputProps={{ maxLength: 30 }}
                      />
                      <TextField
                        value={editStartTime}
                        onClick={handleTimeModalOpen}
                        fullWidth
                        label="Start Time"
                        sx={{ mt: 1 }}
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        value={editEndTime}
                        onClick={handleTimeModalOpen}
                        fullWidth
                        label="End Time"
                        sx={{ mt: 1 }}
                        InputProps={{ readOnly: true }}
                      />
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <IconButton
                          sx={{
                            color: darkMode ? "#90caf9" : "#0d47a1",
                            "&:hover": {
                              color: "#FF4081",
                            },
                          }}
                          onClick={() => handleSaveItem(index)}
                          color="primary"
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            color: darkMode ? "#90caf9" : "#0d47a1",
                            "&:hover": {
                              color: "#FF4081",
                            },
                          }}
                          onClick={handleCancel}
                          color="secondary"
                        >
                          <CancelIcon />
                        </IconButton>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <MDTypography variant="body1" mt={1}>
                        {truncateName(showing.name)}
                      </MDTypography>
                      <MDTypography variant="body2">
                        {showing.startTime} - {showing.endTime}
                      </MDTypography>
                    </>
                  )}
                </MDBox>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      <TimeModal
        open={isTimeModalOpen}
        onSubmit={handleTimeSubmit}
        onCancel={() => setTimeModalOpen(false)}
        mediaName={editName}
        startTime={editStartTime}
        endTime={editEndTime}
        isTimeOverlap={isTimeOverlap}
        currentIndex={editingIndex}
      />
    </MDBox>
  );
};

export default ScheduleShowings;
