import { fetchUtils, HttpError } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import store from "../store";

const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
const adminApiUrl = `${process.env.REACT_APP_API_URL}/api/admin`;

const httpClient = async (url, options = {}) => {
  const state = store.getState();
  const subdomain = state.subdomain.value;

  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  options.headers.set("X-Subdomain", subdomain);

  try {
    const response = await fetchUtils.fetchJson(url, options);
    return response;
  } catch (error) {
    // Parse the error body if available
    if (error.body && typeof error.body === "string") {
      try {
        const parsedError = JSON.parse(error.body);
        return Promise.reject(
          new HttpError(
            parsedError.error || error.message,
            error.status,
            parsedError
          )
        );
      } catch {
        return Promise.reject(
          new HttpError("An unknown error occurred.", error.status)
        );
      }
    }

    return Promise.reject(error); // Pass along any non-JSON errors
  }
};

const regularProvider = simpleRestProvider(apiUrl, httpClient);
const adminProvider = simpleRestProvider(adminApiUrl, httpClient);

// Customized data provider to route specific resources to the admin API
const dataProvider = {
  ...regularProvider,
  getList: (resource, params) => {
    if (resource === "superadmins" || resource === "users") {
      return adminProvider.getList(resource, params);
    }
    return regularProvider.getList(resource, params);
  },
  getOne: (resource, params) => {
    if (resource === "superadmins" || resource === "users") {
      return adminProvider.getOne(resource, params);
    }
    return regularProvider.getOne(resource, params);
  },
  create: (resource, params) => {
    if (resource === "superadmins" || resource === "users") {
      return adminProvider.create(resource, params);
    }
    return regularProvider.create(resource, params);
  },
  update: (resource, params) => {
    if (resource === "superadmins" || resource === "users") {
      return adminProvider.update(resource, params);
    }
    return regularProvider.update(resource, params);
  },
  delete: (resource, params) => {
    if (resource === "superadmins" || resource === "users") {
      return adminProvider.delete(resource, params);
    }
    return regularProvider.delete(resource, params);
  },
};

export default dataProvider;
